<template>
  <div class="manage-card">
    <div class="prof">
      <img
        v-if="this.$store.state.user.image != null"
        :src="this.$store.state.user.image.small"
        class="prof-img"
        alt=""
      />
      <img
        v-else
        src="../../assets/images/png/pro-img.png"
        class="prof-img"
        alt=""
      />
      <span class="prof-name"
        >{{ $store.state.user.first_name }}
        {{ $store.state.user.last_name }}</span
      >
      <span class="prof-sub">{{ $t("tourist") }}</span>
    </div>
    <div class="prof-links">
      <router-link to="/dashboard/eats">
        <img src="../../assets/images/icons/link1.svg" alt="" />{{
          $t("Taomlar")
        }}
      </router-link>
      <router-link to="/dashboard/bookings">
        <img src="../../assets/images/icons/link2.svg" alt="" />{{
          $t("Buyurtmalar")
        }}
        <span>2</span>
      </router-link>
      <router-link to="/dashboard/settings">
        <img src="../../assets/images/icons/link3.svg" alt="" />{{
          $t("Profil")
        }}
        {{ $t("sozlamalari") }}
      </router-link>
     <a @click="exitActive=!exitActive"  class="for-exit">
        <img src="../../assets/images/icons/link4.svg" alt="" />{{
          $t("Chiqish")
        }}
          <div class="exit-card" :class="{'active':exitActive}">
            <span class="e-title">Are you sure to exit</span>  
            <span class="e-sub">Commonly used to demonstrate the or a without relying full.</span> 
            <button @click="logOut()" class="exit-btn">chiqish</button>
          </div>
        </a>
    </div>
  </div>
</template>

<script>
export default {
  mounted(){
   }
};
</script>

<style>
.prof-links .router-link-active {
  background: #fff;
}
</style>